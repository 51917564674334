import React from 'react';
import Layout from '../../components/Layout';
import { SectionTitle, Paragraph, Pill } from '../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin,faStackOverflow,faGithub } from '@fortawesome/free-brands-svg-icons';


const Me = ({ user }) => {
  return (
    <Layout user={user}>
      <div>
      <SectionTitle>Follow Me</SectionTitle>
      <a href="https://www.instagram.com/abi_chhetri_/" target="_blank">
        <FontAwesomeIcon icon={faInstagram} size="3x"/>
      </a>
      <a style={{'margin-left':'2%'}} href="https://www.facebook.com/gc.abishek.9/" target="_blank">
        <FontAwesomeIcon icon={faFacebook} size="3x"/>
      </a>
      <a style={{'margin-left':'2%'}} href="#" target="_blank">
        <FontAwesomeIcon icon={faLinkedin} size="3x"/>
      </a>
      <a style={{'margin-left':'2%'}} href="#" target="_blank">
        <FontAwesomeIcon icon={faStackOverflow} size="3x"/>
      </a>
      <a style={{'margin-left':'2%'}} href="https://github.com/avichhetri8" target="_blank">
        <FontAwesomeIcon icon={faGithub} size="3x"/>
      </a>
      </div>
      <div>
        <SectionTitle>About Me</SectionTitle>
        <Paragraph>{user.basics.summary}</Paragraph>
      </div>
      <div>
        <SectionTitle>Skills</SectionTitle>
        <div>
          {user.skills.map(skill => (
            <Pill key={skill.name}>{skill.name}</Pill>
          ))}
        </div>
      </div>
      <div>
        
      </div>
    </Layout>
  );
};

export default Me;
