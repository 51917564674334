import React from 'react';

const GoogleDocsViewerFile = (props) => {
    
    let iframeSrc = props.src;

    let style = {
      width: props.width,
      height: props.height,
      border: 'none',
      float:'right',
      position: 'fixed',
      overflow: 'hidden',
      zIndex: '999999'
    };

    return (
      <div>
        <iframe src={iframeSrc}
          style={style}></iframe>
      </div>
    );
  
}

export default GoogleDocsViewerFile;