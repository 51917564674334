import React from 'react';
import GoogleDocsViewerFile from '../../notebook/component/GoogleDocsViewer';
import Sidebar from '../../notebook/component/Sidebar/Sidebar';
import styled from 'styled-components'
import { Content } from 'carbon-components-react/lib/components/UIShell'

const Notes = () => {

return(
    <>
    <Sidebar></Sidebar>
    <StyledContent>
      <GoogleDocsViewerFile 
        src="https://drive.google.com/file/d/1TUEPY2qaFglNKiGrjkE_XN4GtQqUwHj0/preview"
        width="80%"
        height="95%"/>
        </StyledContent>
    </>
    )

}

export const StyledContent = styled(Content)`
  min-height: 100%;

  @media (max-width: 640px) {
    margin-left: 0 !important;
    top: 20%;
    iframe{
      margin-right:0% !important;
      width:98% !important;
      height:90% !important;
    }
  }
`

export default Notes;
